import Swiper from 'swiper'
import {Autoplay, Pagination, EffectCreative} from 'swiper/modules';

(() => {
    const slider = document.querySelector('[data-home-swiper]') as HTMLElement;
    if (!slider) return;

    const homeSwiper = new Swiper(slider, {
        allowTouchMove: document.body.classList.contains('is-mobile'),
        slidesPerView: 1,
        modules: [Autoplay, Pagination, EffectCreative],
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-40%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        speed: 800,
        autoplay: {
            delay: 8000,
        },
        pagination: {
            el: '[data-swiper-pagination]',
            clickable: true,
            bulletClass: 'Bullet',
            bulletActiveClass: 'is-active',
            renderBullet: function (i, className) {
                const index = i + 1,
                    slide = document.querySelector(`.HomeSlide:nth-child(${index})`) as HTMLElement

                return `<div class="${className}"><div class="BulletInner"><span class="BulletIndex">0${index}</span><span class="BulletTitle">${slide.dataset.title}</span></div><div class="BulletInner BulletInner--active"><span class="BulletIndex">0${index}</span><span class="BulletTitle">${slide.dataset.title}</span></div></div>`
            }
        },
    });
})();
