import {createApp} from "petite-vue";

import.meta.glob([ '@images/**' ])
import "@styles/main.sass"
import "./home.ts"

interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

const headerEl = document.querySelector('[data-header]'),
    headerScope: Header = {
        opened: false,
        handleOpenedHeader(state) {
            document.body.classList.toggle("u-hiddenOverflow", state)
            document.querySelector('main')[state ? 'addEventListener' : 'removeEventListener']("click", () => { this.opened = false })
        }
    }

headerEl && createApp(headerScope).mount(headerEl)
